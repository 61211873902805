import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import assureEffort from "../../Assets/Projects/assure_effort.png";
import gemsEssence from "../../Assets/Projects/gems_essence_web.png";
import busBooking from "../../Assets/Projects/busBooking.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <h3 style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </h3>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={assureEffort}
              showButtons={true}
              title="Assure Effort"
              description="Comprehensive application catering both employee and company needs. Allows tracking of employee productive and billable time on projects. On Admin side, facilitates company and project management."
              ghLink="#"
              demoLink="https://assure-effort.gemsessence.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gemsEssence}
              showButtons={true}
              title="Gems Essence Web"
              description="API only application powering the Gems Essence Website with complete dynamic content, Developed efficient and scalable REST API with JWT authentication for featuring dynamic data on company website."
              ghLink="#"
              demoLink="https://gemsessence.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={busBooking}
              showButtons={true}
              title="ReadyGO"
              description="A Bus Booking Application built with Rails 7 facilitating bus reservations by users. Allows Bus Service Owners to list down their buses and services. Keeps track of user's travel history and payment history."
              ghLink="#"
              demoLink="#"              
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
